export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const THUMBNAIL_WIDTH = 60;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const SPECS = {
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  HideBillingFormForPayPalAndManualNotBrazil: 'specs.stores.HideBillingFormForPayPalAndManualNotBrazil',
  EnablePlaceOrderButtonForExistingNoAddressCustomer: 'specs.stores.EnablePlaceOrderButtonForExistingNoAddressCustomer',
  WithCheckoutHackForReact18Tests: 'specs.stores.WithCheckoutHackForReact18Tests',
  GiftCartSeviceDownCheckout: 'specs.stores.GiftCartSeviceDownCheckout',
  MoveSlotAboveCheckoutButton: 'specs.stores.MoveSlotAboveCheckoutButton',
  ShowExpressCheckoutButtonsOnInitialStep: 'specs.stores.ShowExpressCheckoutButtonsOnInitialStep',
  ShowVatIdOnBillingForIsrael: 'specs.stores.ShowVatIdOnBillingForIsrael',
  AddLayoutConfigToLineItemsAutoGql: 'specs.stores.AddLayoutConfigToLineItemsAutoGql',
  ShouldHandlePaylinksRedirectError: 'specs.stores.ShouldHandlePaylinksRedirectError',
  UseNewSubscriptionCheckboxInCustomerDetailsForm: 'specs.stores.UseNewSubscriptionCheckboxInCustomerDetailsForm',
  RefactorFormServiceToCalcExtendedFields: 'specs.stores.RefactorFormServiceToCalcExtendedFields',
  MigrateSubscriptionToCheckoutForm: 'specs.stores.MigrateSubscriptionToCheckoutForm',
  AddSlotToCheckoutPolicies: 'specs.stores.AddSlotToCheckoutPolicies',
  TestNewSubscriptionCheckboxLocation: 'specs.stores.TestNewSubscriptionCheckboxLocation',
  UseNewSubscriptionView: 'specs.stores.UseNewSubscriptionView',
  AddPoliciesToLineItems: 'specs.stores.AddPoliciesToLineItems',
  SupportMemberOnlyCheckout: 'specs.stores.SupportMemberOnlyCheckout',
  SupportFreeTrialLineItems: 'specs.stores.SupportFreeTrialLineItems',
  CheckoutFromSDKInExpressCheckoutButtons: 'specs.stores.CheckoutFromSDKInExpressCheckoutButtons',
  SupportDeliveryProfilesPhase2: 'specs.stores.SupportDeliveryProfilesPhase2',
  ShouldFixZoomForCheckoutInClassic: 'specs.stores.ShouldFixZoomForCheckoutInClassic',
  ShouldScrollToPaymentWidgetOnPaymentFormError: 'specs.stores.ShouldScrollToPaymentWidgetOnPaymentFormError',
  GiftCardAddToCartSettings: 'specs.stores.ShowGiftCardAddToCartSettings',
  FixCheckoutHeaderCSS: 'specs.stores.FixCheckoutHeaderCSS',
  UsePaymentRequired: 'specs.stores.UsePaymentRequired',
  ExtractShippingAddFieldsToCheckoutModel: 'specs.stores.ExtractShippingAddFieldsToCheckoutModel',
  RefactorPlaceOrderStateCalculation: 'specs.stores.RefactorPlaceOrderStateCalculation',
  UseDeliverySectionFromLib: 'specs.stores.UseDeliverySectionFromLib',
  FixScrollToPaymentWidgetOnPaymentFormError: 'specs.stores.FixScrollToPaymentWidgetOnPaymentFormError',
  HideDepositWhenZero: 'specs.stores.HideDepositWhenZero',
  FixCouponError: 'specs.stores.FixCouponError',
};
