import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import type {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {createDeliverySectionController, DeliverySectionController} from '@wix/delivery-section-checkout/controller';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {DeliveryStoreProps} from '../../../types/checkoutApp.types';

export type DeliverySectionStoreConfig = {
  flowAPI: ControllerFlowAPI;
  controllerConfig: IWidgetControllerConfig;
  checkoutService: CheckoutService;
};

export class DeliveryStore {
  private readonly flowApi: ControllerFlowAPI;
  private readonly controllerConfig: IWidgetControllerConfig;
  private deliverySectionController!: DeliverySectionController;
  private readonly checkoutService!: CheckoutService;

  constructor({flowAPI, controllerConfig, checkoutService}: DeliverySectionStoreConfig) {
    this.flowApi = flowAPI;
    this.controllerConfig = controllerConfig;
    this.checkoutService = checkoutService;
  }

  public readonly init = () => {
    this.setDeliveryController();
    void this.deliverySectionController.init();
  };

  private readonly setDeliveryController = () => {
    this.deliverySectionController = createDeliverySectionController({
      flowAPI: this.flowApi,
      shouldShowOnlyPickup: false,
      controllerConfig: this.controllerConfig,
      getCheckoutV1: () => Promise.resolve({}),
      setDeliveryMethodId: /*istanbul ignore next*/ () => Promise.resolve(),
      onStateChanged: /*istanbul ignore next*/ () => Promise.resolve(),
    });
  };

  public toProps(): DeliveryStoreProps {
    return {
      hasSelectedOption: !!this.checkoutService.checkout.selectedShippingOption,
    };
  }
}
